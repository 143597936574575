import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "login",
    component: () => import("../views/LoginView.vue"),
  },

  {
    path: "/login",
    name: "login2",
    component: () => import("../views/LoginView.vue"),
  },

  {
    path: "/trans",
    name: "trans",
    component: () => import("../views/TransactionView.vue"),
  },

  {
    path: "/trans2",
    name: "trans2",
    component: () => import("../views/TransactionView2.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
