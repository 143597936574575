<template>
  
  <!-- SIDEBAR -->
  <div class="sidebar" id="sidebar">
                <div class="sidebar__head sbbar" style="position: relative; ">
                    <!-- <a class="sidebar__logo" href="index.html" >
                        <img class="sidebar__pic sidebar__pic_light" src="./img/logo_long.png" alt="" style="width: 170px;"/>
                        <img class="sidebar__pic sidebar__pic_dark" src="img/logo_long.png" alt="" style="width: 170px;"/>
                        
                    </a> -->

                    <div style="display: flex; justify-content: center; align-items: center; flex-direction: column; ">

                        <img :src="profilePic" id="profilePic" alt="" style="width: 100px; border-radius: 50%; margin-top: 100px; background-color: #484848; ">
                        <p style="margin-top: 10px; font-size: 18px;" id="profileName">Admin</p>
                        

                    </div>
                    <!-- <button class="sidebar__toggle">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24">
                            <path d="M22 12H3" stroke="#11142d"></path>
                            <g stroke="#808191">
                                <path d="M22 4H13"></path>
                                <path opacity=".301" d="M22 20H13"></path>
                            </g>
                            <path d="M7 7l-5 5 5 5" stroke="#11142d"></path>
                        </svg>
                    </button> -->
                    <button class="sidebar__close" style="position: absolute; right: 5px; top: 0;" @click="closeSide()">
                        <svg class="icon icon-close">
                            <use xlink:href="@/assets/img/sprite.svg#icon-close"></use>
                        </svg>
                    </button>
                </div>
                <div class="sidebar__body" style="margin-top: 100px;">

                  

                    <nav class="sidebar__nav">
                        <div class="hl"></div>

                        <!-- <router-link  @click="closeSide()" :to="{name: 'portfolio'}" id="portfolio" class="sidebar__item " style="margin-top: 15px;">
                            <div class="sidebar__icon">
                                <svg class="icon icon-wallet">
                                    <use xlink:href="@/assets/img/sprite.svg#icon-wallet"></use>
                                </svg>
                            </div>
                            <div class="sidebar__text">Portfolio</div>
                        </router-link>

                        <router-link @click="closeSide()" :to="{name: 'swap'}" id="swap" class="sidebar__item" style="margin-top: 10px;">
                            <div class="sidebar__icon">
                                <svg class="icon icon-discount">
                                    <use xlink:href="@/assets/img/sprite.svg#icon-home"></use>
                                </svg>
                            </div>
                            <div class="sidebar__text">Swap</div>
                        </router-link>

                        <router-link @click="closeSide()" :to="{name: 'staking'}" id="staking" class="sidebar__item"  style="margin-top: 10px;">
                            <div class="sidebar__icon">
                                <svg class="icon icon-activity">
                                    <use xlink:href="@/assets/img/sprite.svg#icon-activity"></use>
                                </svg>
                            </div>
                            <div class="sidebar__text">Staking</div>
                        </router-link> -->

                        <router-link @click="closeSide()" :to="{name: 'trans'}" id="trans" class="sidebar__item" style="margin-top: 10px;">
                            <div class="sidebar__icon">
                                <svg class="icon icon-notification">
                                    <use xlink:href="@/assets/img/sprite.svg#icon-document"></use>
                                </svg>
                            </div>
                            <div class="sidebar__text">Transactions (Receive)</div>
                        </router-link>

                        <router-link @click="closeSide()" :to="{name: 'trans2'}" id="trans2" class="sidebar__item" style="margin-top: 10px;">
                            <div class="sidebar__icon">
                                <svg class="icon icon-notification"> 
                                    <use xlink:href="@/assets/img/sprite.svg#icon-document"></use>
                                </svg>
                            </div>
                            <div class="sidebar__text">Transactions (Send)</div>
                        </router-link>

                        <!-- <router-link @click="closeSide()" :to="{name: 'markets'}" id="markets" class="sidebar__item" style="margin-top: 10px;">
                            <div class="sidebar__icon">
                                <svg class="icon icon-settings">
                                    <use xlink:href="@/assets/img/sprite.svg#icon-calendar"></use>
                                </svg>
                            </div>
                            <div class="sidebar__text">Markets</div>
                        </router-link> -->

                        <a href="https://admin.navox.io/login" class="sidebar__item" style="margin-top: 10px;">
                            <div class="sidebar__icon">
                                <svg class="icon icon-settings">
                                    <use xlink:href="@/assets/img/sprite.svg#icon-arrow-down-square"></use>
                                </svg>
                            </div>
                            <div class="sidebar__text">Sign out</div>
                        </a>
                    </nav>
                    
                </div>
                
  </div>

  <router-view/>


  <!-- MODALS -->

  <!-- LOADING -->
        <div style=" display: flex; justify-content: center; align-items: center; z-index: 999999;" class="loadingModalBg" ref="loadingModal" >

            <div class="popup popup_setting "  style="width: 300px; padding: 30px; border-radius: 20px;">
            

                <div class="operations__item" style="padding: 0; margin: 0; ">
                    
                    <div style="width: 100%; display: flex; justify-content: center; align-items: center;">

                        <img src="@/assets/img/loading.png" alt="" style="width: 250px;">

                    </div>

                    <div class="popup__title h6" style="text-align: center; margin-top: 20px;">Please wait</div>

                    <div class="operations__text" style="margin-top: -10px; font-size: 14px; text-align: center;">
                        
                        {{ loadingText }}
                        
                    </div>

                    <div style="width: 100%; display: flex; justify-content: center; align-items: center;">

                        <img src="@/assets/img/loading.gif" alt="" style="width: 50px; margin-top: -10px; margin-bottom: -20px;">

                    </div>
                                            
                                            
                </div>

                
            </div>

        </div>

        <!-- ERROR -->
        <div style=" display: flex; justify-content: center; align-items: center; z-index: 999999;" class="loadingModalBg " ref="errorModal">

            <div class="popup popup_setting "  style="width: 300px; padding: 30px; border-radius: 20px;">
            

                <div class="operations__item" style="padding: 0; margin: 0;">
                    
                    <div style="width: 100%; display: flex; justify-content: center; align-items: center;">

                        <img src="@/assets/img/error.png" alt="" style="width: 250px;">

                    </div>

                    <div class="popup__title h6" style="text-align: center; margin-top: 20px; color: #EA4497;">Error!</div>

                    <div class="operations__text" style="margin-top: -10px; font-size: 14px; text-align: center;">
                        
                        {{ errorText }}
                        
                    </div>

                    <div style="width: 100%; display: flex; justify-content: center; align-items: center; margin-top: 10px;">

                        <button class="btn btn_blue btn_sm" style="min-width: 30px;" @click="closeE()">Okay</button>

                    </div>
                                            
                                            
                </div>

                
            </div>

        </div>

        <!-- SUCCESS -->
        <div style=" display: flex; justify-content: center; align-items: center; z-index: 999999;" class="loadingModalBg " ref="successModal">

            <div class="popup popup_setting "  style="width: 300px; padding: 30px; border-radius: 20px;">
            

                <div class="operations__item" style="padding: 0; margin: 0;">
                    
                    <div style="width: 100%; display: flex; justify-content: center; align-items: center;">

                        <img src="@/assets/img/success.png" alt="" style="width: 250px;">

                    </div>

                    <div class="popup__title h6" style="text-align: center; margin-top: 20px; color: #4FBF67;">Success</div>

                    <div class="operations__text" style="margin-top: -10px; font-size: 14px; text-align: center;">
                        
                        {{ successText }}
                        
                    </div>

                    <div style="width: 100%; display: flex; justify-content: center; align-items: center;">

                        <button class="btn btn_blue btn_sm" style="min-width: 30px; margin-top: 10px;" @click="closeS()">Okay</button>

                    </div>

                    

                    
                                            
                                            
                </div>

                
            </div>

        </div>

        <!-- SUCCESS2 -->
        <div style=" display: flex; justify-content: center; align-items: center; z-index: 999999;" class="loadingModalBg " ref="successModal2">

            <div class="popup popup_setting "  style="width: 300px; padding: 30px; border-radius: 20px;">
            

                <div class="operations__item" style="padding: 0; margin: 0;">
                    
                    <div style="width: 100%; display: flex; justify-content: center; align-items: center;">

                        <img src="@/assets/img/success.png" alt="" style="width: 250px;">

                    </div>

                    <div class="popup__title h6" style="text-align: center; margin-top: 20px; color: #4FBF67;">Success</div>

                    <div class="operations__text" style="margin-top: -10px; font-size: 14px; text-align: center;">
                        
                        {{ successText2 }}
                        
                    </div>

                    <div style="width: 100%; display: flex; justify-content: center; align-items: center;">

                        <button class="btn btn_blue btn_sm" style="min-width: 30px; margin-top: 10px;" @click="closeS2()">Okay</button>

                    </div>

                    

                    
                                            
                                            
                </div>

                
            </div>

        </div>

</template>

<style>
  @import url(./assets/css/app.css);
</style>

<script>

    import { doc, getDoc, addDoc, collection, query, where, getDocs, orderBy, updateDoc } from "firebase/firestore";
    import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
    import { db } from '@/firebase';

    export default {

        data(){
            return{
                profileName: "",
                email: "",
                profilePic: "",

                errorText: "",
                loadingText: "",
                successText: "",
                successText2: "",


                password: "",
                
                cNewPassword: "",
                newPassword: "",
                oldPassword: "",

                imagePassword: "",

                faStatus: "",
                faPassword: "",

                recoveryPassword: "",
                phrase: "",

                refLink: "",
                totalRefs: 0,
                refBalance: 0,
            }
        },
        
        mounted(){

            //set body
            document.body.classList.add('dark');


            this.options();
            this.fieldInput();

        },

        methods:{

            closeSide(){

                document.getElementById("sidebar").classList.remove("active");

            }, 

            fieldInput(){

                $('.js-field-input').focusin(function () {
                    $(this).parents('.js-field').addClass('active');
                });
                $('.js-field-input').focusout(function () {
                    var _this = $(this),
                        field = _this.parents('.js-field'),
                        value = _this.val();
                    if (value == '') {
                        field.removeClass('active');
                    }
                });

            },
        

            options(){

                let option = $('.js-tabs-select .option');
                let tabs = $('.js-tabs');
                tabs.each(function () {
                    let thisTabs = $(this),
                        nav = thisTabs.find('.js-tabs-link'),
                        option = thisTabs.find('.option'),
                        item = thisTabs.find('.js-tabs-item');
                    nav.on('click', function () {
                        let thisNav = $(this),
                            indexNav = thisNav.index();
                        nav.removeClass('active');
                        thisNav.addClass('active');
                        item.hide();
                        item.eq(indexNav).fadeIn();
                        return false;
                    });
                });

                

                

                option.on('click', function () {
                    let thisOption = $(this),
                        indexOption = thisOption.index();
                    console.log(indexOption);
                    $('.js-tabs-item').hide();
                    $('.js-tabs-item').eq(indexOption).fadeIn();
                });
            },

            openImage(){
                this.$refs.imageModal.classList.add("bg-active");  
            },

            closeImage(){
                this.$refs.imageModal.classList.remove("bg-active");  
            },

            closeP(){
                this.$refs.phraseModal.classList.remove("bg-active");  
            },

            copyPhrase(){
                navigator.clipboard.writeText(this.phrase);
                this.$refs.phraseModal.classList.remove("bg-active");
            },

            copyLink(){
                navigator.clipboard.writeText(this.refLink);
                this.successText2 = "Referral link has been copied to your clipboard"
                this.$refs.successModal2.classList.add("bg-active");
            },

            openPhrase(){

                //start loading
                this.loadingText= " Please wait...";
                this.$refs.loadingModal.classList.add("bg-active"); 

                if(this.recoveryPassword != this.password ){
                    this.errorText = "Incorrect password"
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    return;
                }

                this.$refs.loadingModal.classList.remove("bg-active");
                this.$refs.phraseModal.classList.add("bg-active");

            },

            closeS(){
                this.$refs.successModal.classList.remove("bg-active");  
                this.$router.go();
            },

            closeS2(){
                this.$refs.successModal2.classList.remove("bg-active");  
        
            },

            closeE(){
                this.$refs.errorModal.classList.remove("bg-active");
            },

            async updateImg(){

                //start loading
                this.loadingText = "Uploading image..."
                this.$refs.loadingModal.classList.add("bg-active");

                let imageFile = document.getElementById("imageFile");

                if(this.imagePassword != this.password){
                    this.errorText = "Incorrect password";
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    return;
                }

                if(imageFile.files[0].size > 3000000){
                    this.errorText = "Image is too large. Minimum size for image is 3MB";
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    return;
                }

                const storage = getStorage();

                // Create the file metadata
                /** @type {any} */

                const metadata = {
                    contentType: 'image/*'
                };

                // Upload file and metadata to the object 'images/mountains.jpg'
                const storageRef = ref(storage, 'UserImages/profiles/' + imageFile.files[0].name);
                const uploadTask = uploadBytesResumable(storageRef, imageFile.files[0], metadata);

                // Listen for state changes, errors, and completion of the upload.
                uploadTask.on('state_changed',
                (snapshot) => {
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log('Upload is ' + progress + '% done');
                    switch (snapshot.state) {
                    case 'paused':
                        console.log('Upload is paused');
                        break;
                    case 'running':
                        console.log('Upload is running');
                        break;
                    }
                },
                (error) => {
                    // A full list of error codes is available at
                    // https://firebase.google.com/docs/storage/web/handle-errors
                    switch (error.code) {
                    case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        break;
                    case 'storage/canceled':
                        // User canceled the upload
                        break;

                    // ...

                    case 'storage/unknown':
                        // Unknown error occurred, inspect error.serverResponse
                        break;
                    }
                }, 
                () => {
                    // Upload completed successfully, now we can get the download URL
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        console.log('File available at', downloadURL);
                        this.updatePic(downloadURL);
                        
                    });
                }); 


            },

            async updatePic(url){

                //update balance
                const userRef = doc(db, "Users", sessionStorage.getItem("id"));
                await updateDoc(userRef, {
                    profilePic: url
                });

                this.successText = "Image was uploaded successfully";
                this.$refs.loadingModal.classList.remove("bg-active");
                this.$refs.successModal.classList.add("bg-active");

            },

            async changePassword(){
                //start loading
                this.loadingText= "Updating password...";
                this.$refs.loadingModal.classList.add("bg-active"); 

                if(this.password != this.oldPassword ){
                    this.errorText = "Incorrect password"
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    return;
                }

                if(this.newPassword != this.cNewPassword ){
                    this.errorText = "Passwords do not match"
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    return;
                }

                if(this.newPassword.length < 8 ){
                    this.errorText = "Password can not be less than 8 characters"
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    return;
                }

                if(this.newPassword == this.password ){
                    this.errorText = "New password can not be the same as old password"
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    return;
                }

                //update password
                const userRef = doc(db, "Users", sessionStorage.getItem("id"));
                await updateDoc(userRef, {
                    password: String(this.newPassword)
                });

                this.successText = "Password changed successfully"
                this.$refs.loadingModal.classList.remove("bg-active");
                this.$refs.successModal.classList.add("bg-active");

            },

            async update2fa(){
                //start loading
                this.loadingText= "Updating 2FA status...";
                this.$refs.loadingModal.classList.add("bg-active"); 

                if(this.faPassword != this.password ){
                    this.errorText = "Incorrect password"
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    return;
                }

                //update status
                const userRef = doc(db, "Users", sessionStorage.getItem("id"));

                if(this.faStatus == "Enable 2FA"){
                    await updateDoc(userRef, {
                        auth: "yes"
                    });
                }
                else{
                    await updateDoc(userRef, {
                        auth: "no"
                    });
                }

                this.successText = "2FA status updated successfully"
                this.$refs.loadingModal.classList.remove("bg-active");
                this.$refs.successModal.classList.add("bg-active");
                

            },

        }

    }
</script>
